<template>
  <div >

    <a-card title="规格属性">

      <a-table :columns="specsColumns" :data-source="specs" bordered size="middle" :pagination="false">
        <template slot="values" slot-scope="text, record, index">
          <a-tag closable v-for="(item,tagIndex) in record.values" :key="item" style="margin: 2px"
                 @close="deleteTag(index,tagIndex)">{{item}}</a-tag>
          <a-tag  style="borderStyle: dashed;margin: 2px;cursor: pointer" color="blue" @click="clickAddValue(index)">新增</a-tag>
        </template>

        <template slot="operation" slot-scope="text, record, index">
          <a-button size="small" type="danger" ghost @click="clickDeleteGroup(index)">删除该组</a-button>
        </template>

      </a-table>

      <a-button type="primary" style="margin-top: 8px" ghost @click="clickAddGroup">添加属性</a-button>

    </a-card>


    <a-card title="售价库存" style="margin-top: 10px">
      <a-table :columns="skuColumns" :data-source="skuList" bordered size="middle" :pagination="false">

        <template slot="price" slot-scope="text, record, index">
          <a-input prefix="￥" :value="text/100 || ''" @blur="changePrice($event,index)"></a-input>
        </template>

        <template slot="costPrice" slot-scope="text, record, index">
          <a-input prefix="￥" :value="text/100 || ''" @blur="changeCostPrice($event,index)"></a-input>
        </template>

        <template slot="stock" slot-scope="text, record, index">
          <a-input :value="text" @blur="changeStock($event,index)"></a-input>
        </template>

      </a-table>
    </a-card>

    <input-dialog ref="inputDialog"></input-dialog>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        specs: [],

        skuList: [
          {
            specsName: "",
            costPrice: "",
            price: "",
            stock: ""
          }
        ],
        specsColumns: [
          {
            title: '属性',
            dataIndex: 'name',
          },
          {
            title: '属性值',
            dataIndex: 'values',
            scopedSlots: {customRender: 'values'},
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          },
        ],

        skuColumns: [
          {
            title: '规格',
            dataIndex: 'specsName',
            width:200
          },
          {
            title: '售价',
            dataIndex: 'price',
            scopedSlots: {customRender: 'price'},
          },
          {
            title: '成本价（选填）',
            dataIndex: 'costPrice',
            scopedSlots: {customRender: 'costPrice'},
          }, {
            title: '库存',
            dataIndex: 'stock',
            scopedSlots: {customRender: 'stock'},
          }

        ]
      }
    },
    computed:{

    },
    created() {
      setTimeout(()=>{
        this.updateSkuList()
      },200)
    },
    methods: {
      deleteTag(index, tagIndex) {
        let specs = JSON.parse(JSON.stringify(this.specs))
        specs[index].values.splice(tagIndex,1)
        this.specs=specs
        this.updateSkuList()
      },
      clickAddGroup(){
        let self = this
        this.$refs.inputDialog.show({
          title:"添加属性",
          value:"",
          placeholder:"如颜色、尺寸、型号",
          onOk(value){
            self.specs.push({
              name:value,
              values:[]
            })
          }
        })
      },
      clickAddValue(index){
        let self = this
        this.$refs.inputDialog.show({
          title:"添加 " + self.specs[index].name + " 属性值",
          value:"",
          placeholder:"",
          onOk(value){
            self.specs[index].values.push(value)
            self.updateSkuList()
          }
        })
      },
      clickDeleteGroup(index){
        let self = this
        this.$confirm({
          title: '是否删除所有 ' + self.specs[index].name,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            self.specs.splice(index,1)
            self.updateSkuList()
          }
        })
      },

      changePrice(e, index) {
        this.skuList[index].price = parseInt(parseFloat(e.target.value) * 100)
      },

      changeCostPrice(e, index) {
        this.skuList[index].costPrice = parseInt(parseFloat(e.target.value) * 100)
      },

      changeStock(e, index) {
        this.skuList[index].stock = parseInt(e.target.value)
      },

      updateSkuList(){
        let newList = this.getSkuList(this.specs)
        let oldMap = {}
        this.skuList.forEach(item=>{
          oldMap[item.specsName] = item
        })
        newList.forEach(item=>{
          if(oldMap[item.specsName]){
            Object.assign(item,oldMap[item.specsName])
          }
        })
        this.skuList = newList
      },

      getSkuList(specs) {
        specs = specs.filter(item=>item.values.length>0)
        if(specs.length==0){
          return [{
            specsName: "",
            costPrice: "",
            price: "",
            stock: ""
          }]
        }
        let list = []
        let totalCount = 1
        specs.forEach(item => {
          totalCount *= item.values.length
        })

        for (let i = 0; i < totalCount; i++) {
          list.push([])
        }

        for (let i = 0; i < totalCount; i++) {

          for (let j = 0; j < specs.length; j++) {

            let leftCount = 1
            for (let index = 0; index < j; index++) {
              console.log(index)
              leftCount *= specs[index].values.length
            }

            let rightCount = 1
            for (let index = j + 1; index < specs.length; index++) {
              rightCount *= specs[index].values.length
            }

            let value = parseInt((i % (totalCount / leftCount)) / rightCount)
            list[i][j] = specs[j].values[value]
          }

        }

        let skuList = []
        list.forEach(item => skuList.push({
          specsName: item.join(" "),
          price:"",
          costPrice:"",
          stock:""
        }))
        return skuList
      },

      getData(){
        return {
          specs:this.specs,
          skuList:this.skuList
        }
      },
      setData(specs,skuList){
        this.specs = specs
        this.skuList = skuList
      }
    }
  }
</script>

<style lang="less" scoped>
  .goods-item {
    display: flex;
    padding: 5px 10px;

    .name {
      flex: 1;
      margin-left: 10px;
    }

    .price {
      text-align: right;
      color: #333333;
      font-size: 14px;
    }

    .num {
      text-align: right;
      color: #999999;
      font-size: 12px;
    }

  }
</style>
